.literature {
  h1{
    div{
      color: $fontReticient;
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  .keydata {
    width: 600px;
    max-width: 90%;
    margin: 2% auto;
    background: $boxBackground;
    padding: 10px;

    b {
      font-weight: 600;
      font-size: 2rem;
      display: block;
      margin-bottom: 10px;
    }

    table {
      th {
        text-align: right;
        padding-right: 10px;

        &::after{
          content: ": ";
        }
      }
    }
  }

  .source {
    color: $fontReticient;
    font-size: 1.5rem;
  }
}
