
#header{
  position: static;
  z-index:10;
  top: 0;
  height:50px;
  display:grid;
  padding:20px 0 30px 0;
  margin:0 20px 30px 20px;
	grid-template-columns: 320px 1fr;
  border-bottom:2px solid #cfd4dd;

  @media screen and (max-width: $break1) {
    display:block;
  }
  @media screen and (max-width: $break3) {
    margin:0 0 20px 0;
    padding:20px 10px 30px 10px;
  }
}
#header.hidden{
  background: none !important;
  box-shadow:none !important;
}
#header.scrolled {
  position: fixed;
  z-index: 999;
  left:0;
  background: rgba(255,255,255,.95);
  box-shadow: 0 2px 20px 0 #00000026;
  border: none;
  height:50px;
  padding:10px;
  margin: 0;
  width:100%;
  box-sizing: border-box;
}
#header.scrolled .logo img{
  height:24px;
  width: auto;
}
#header.scrolled .logo div{
  padding-top:1px;
}
#header.scrolled .logo span:last-child{
  display:none;
}
#header .page-title{
  display:inline-block;
  width:320px;
}
#header .open-nav{
  display:none;
  position:absolute;
  top:20px;
  right:20px;
  width:29px;
  height:23px;
  cursor:pointer;

  @media screen and (max-width: $break1) {
    display:block;
  }
}
#header.scrolled .open-nav{
  top:10px;
  right:10px;

}
#header .open-nav div{
  position:absolute;
  z-index:6;
  box-sizing: border-box;
  height:3px;
  left:0;
  width:100%;
  background:$fontReticient;

  transform: rotate(0deg);

  transition-property: top, transform;
  transition-duration: .2s, .2s;
  transition-timing-function: linear, linear;
  transition-delay: .4s, 0s;
}
#header .open-nav div:nth-child(1){
  top:0;
}
#header .open-nav div:nth-child(2){
  top:10px;
}
#header .open-nav div:nth-child(3){
  top:20px;
}
#header .open-nav.active{
  right:22%;
}
#header .open-nav.active div{
  transition-property: top, transform;
  transition-duration: .2s, .2s;
  transition-timing-function: linear, linear;
  transition-delay: 0s, .4s;
}
#header .open-nav.active div:nth-child(1){
  transform:rotate(-45deg);
  top:10px;
}
#header .open-nav.active div:nth-child(2){
  opacity:0;
}
#header .open-nav.active div:nth-child(3){
  top:10px;
  transform: rotate(45deg);

}
#header nav{
  flex:1;
  text-align:right;
  margin:20px 0 0 0;
  z-index:5;


  @media screen and (max-width: $break1) {
    display:none;
    position:fixed;
    left:0;
    top:0;
    margin:0;
    width:80%;
    height:100%;
    background:#fff;
    opacity: .98;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    text-align:left;
  }
}
#header.scrolled nav{
  margin: 0;
}
#header nav.active{
  display:block;
  padding-top:32px;
}
#header nav .logo{
  display:none;
  margin:10px;

  @media screen and (max-width: $break1) {
      display:flex;
  }
}
#header nav .logo span:last-child{
  display:block !important;
}
#header nav ul li{
  display: inline-block;
  padding: 2px 0;
  margin-right: 15px;

  @media screen and (max-width: $break1) {
      padding: 10px 20px;
  }
}
#header nav ul li a{
  color: #191919;
  text-decoration:none;
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
}
#header nav ul li a:hover, #header nav ul li a.active{
  color: $brandColor;
}
#header nav.active ul li{
  display:list-item;
  padding:0;
  margin:0;
}
#header nav.active ul li a{
  display:block;
  padding:20px 8px;
  margin:0;
}
#header nav.active ul li a:hover{
  border-left: 2px solid #8a8a8a;
  background: #f3f3f3;
}
