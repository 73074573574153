#search {
  padding: 0 20px;

  .pleasewait {
    width: 40px;
    height: 40px;
    margin: 50px 0 0 20px;

    > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $brandColor;
      opacity: .9;
      -webkit-animation: pleasewait-bounce 2s infinite ease-in-out;
      animation: pleasewait-bounce 2s infinite ease-in-out;
    }
  }



  @-webkit-keyframes pleasewait-bounce {
    0%,
    100% {
      -webkit-transform: scale(0.2);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes pleasewait-bounce {
    0%,
    100% {
      transform: scale(0.2);
    }

    50% {
      transform: scale(1);
    }
  }

  #search-content {
    > div {
      display: block;
      margin: 30px 0;

      h3 {
        margin-bottom: 0;

        span {
          display: inline-block;
          margin-left: 10px;
          color: $fontReticient;
          font-size: 14px;
        }
      }

      p {
        position: relative;
        max-height: 140px;
        overflow: hidden;

        &::after{
          position: absolute;
          bottom: -50px;
          left: 0;
          width:100%;
          height: 100px;
          content: "";
          background: linear-gradient(rgba(255,255,255,0) 0,#fff 40px,#fff 41px);
        }
      }

      > a:last-child {
        margin: 10px 0 10px 10px;
        color: $brandColor;

        &:hover {
          font-weight: 600;
        }
      }

    }
  }
}
