.article{
  margin: 20px 32px;
  display:flex;

  @media screen and (max-width: $break1) {
    display:block;
    max-width: 700px;
    margin:0 auto;
  }
}
.article h1 .heading{
  font-size:38px;
  font-family: $headingFont;
  font-weight:500;
}
.article article{
  width:70%;
  max-width: 700px;
  padding-right:30px;

  @media screen and (max-width: $break1) {
    width: 100%;
    padding-right: 0;
  }
}
.article .meta{
  margin: 10px 0;
  text-transform: uppercase;
  font-size: 12px;
}
.article .meta{
  display:flex;
  flex-direction: row;
  list-style-type:none;
  padding:0;
  flex-wrap: wrap;
}
.article .meta li{
  position:relative;
  margin:0 10px;
  padding:0;
}
.article .meta li a[rel=author]:not(:last-of-type) {
  margin-right: 8px;
}
.article .meta li::before{
  display: none;
}
.article .meta li:not(:last-child)::after{
  position:absolute;
  content: "|";
  color:#848484;
  right:-12px;
}
.article .leadimage{
  width:100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center center;
}
.article .leadcredit {
  margin-bottom: 30px;
  color: $fontReticient;
  font-size: 12px;
}
.article article p:first-of-type:first-letter{
  font-weight: 700;
  font-size: 4rem;
  margin: 5px 0 -5px;
  padding: 0 .1em 0 0;
  height: .7em;
  float: left;
}

.article article a{
  color: $brandColor;
  text-decoration:none;
  font-weight:400;
}
.article article a:hover{
  text-decoration:underline;
}

.article article .figure {
  margin: 20px 0;
  color: $fontReticient;
  font-size: 1.5rem;
  text-align: center;

  img {
    max-width: 100%;
  }
  .caption {
    text-align: left;
  }
}
.article aside{
  width:30%;
  min-width:320px;
  padding:20px 6px 6px 6px;

  @media screen and (max-width: $break1) {
    width: 100%;
  }
}
.article aside header{
  margin-top:20px;
}
.article aside .heading:not(header){
  font-size:1.6rem;
  line-height:2rem;
  margin-bottom:10px;
}
.article aside .recommendations .posts{
  display:flex;
  margin-top:10px;
}
.article aside .recommendations .posts > div:not(.img){
  margin:0 8px 0 0;
  width:calc(100% - 120px);
}
.article aside .recommendations .img{
  width:120px;
  height:120px;
  background-position: center center;
  background-size:cover;
  border-radius:4px;
  margin:0 14px 0 0;
}
.article aside .recommendations p{
  font-size:14px;
  color:$fontReticient;
  margin:0;
  line-height:2rem;
}

.article aside .authors img{
  display:block;
  width: 100px;
  height: 100px;
  margin: 10px 0;
  border-radius:4px;
}
.article aside .authors .authorheading{
  display: block;
  font-size: 18px;
}
.article aside .authors p{
  color: $fontReticient;
  font-size: 14px;
  line-height:20px;
  margin: 10px 0;
}
.article aside .authors p:not(:last-of-type) {
  border-bottom: 2px solid #cfd4dd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
