#footer{
  display:flex;
  padding:20px 0 20px 0;
  margin:40px 20px 0 20px;
  border-top:2px solid #cfd4dd;
  font-size:1.4rem;
}
#footer #internal{
  width:calc(100% - 58px);
}
#footer #internal a{
  position:relative;
  margin:0 8px;
}
#footer #internal a:not(:last-child)::after{
  position:absolute;
  content: "·";
  right:-12px;
}
#footer #social a{
  position:relative;
  display:inline-block;
  width:19px;
  height:19px;
  margin:0 6px 0 0;
}
#footer #social a svg{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
