.author .wallpaper{
  position:relative;
  height:360px;
  background-size:cover;
  background-repeat:no-repeat;
  z-index:1;
  margin:0 20px 20px 20px;

  @media screen and (max-width: $break3) {
      background:none !important;
      height:auto;
  }
}
.author .wallpaper::after{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  content: "";
  background-color:rgba(255,255,255,.1);
  z-index:2;
}
.author .wallpaper img{
  position:relative;
  width:120px;
  border:3px solid #fff;
  border-radius:4pt;
  margin:5% 0 0 5%;
  z-index:3
}
.author .wallpaper div{
  position:relative;
  margin:10px 0 0 5%;
  z-index:3;
}
.author .wallpaper div h1{
  color:#fff;

  @media screen and (max-width: $break3) {
      color: $fontColor;
  }

  &.noWallpaper {
    color: $fontColor;
  }
}
.author .wallpaper div p{
  color:#fff;
  margin-top:5px;

  @media screen and (max-width: $break3) {
      color: $fontColor;
  }
}
