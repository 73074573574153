$break1: 980px;
$break2: 650px;
$break3: 460px;

$defaultFont: Lora,sans-serif;
$headingFont: 'Bitter', serif;
$fontColor: #000000;
$fontReticient: #666;
$brandColor: #2c75b4;
$linkColor: #191919;
$boxBackground: #eeeef2;

@import 'reset';
@import 'fonts';
@import 'base';
@import 'header';
@import 'home';
@import 'bloghome';
@import 'post';
@import 'category';
@import 'author';
@import 'literature';
@import 'search';
@import 'footer';
