@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.eot');
  src: local(''),
       url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.woff2') format('woff2'),
       url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.woff') format('woff'),
       url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.ttf') format('truetype'),
       url('/assets/fonts/Bitter/bitter-v16-latin-ext-500.svg#Bitter') format('svg');
}

/* lora-regular - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-700 - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-italic - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-italic.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-700italic - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/lora/lora-v16-latin-ext_latin-700italic.svg#Lora') format('svg'); /* Legacy iOS */
}
