.bloghome > article:first-child{
  grid-column:1/4;
  padding-left:calc(63% + 20px);
  height:520px;

  @media screen and (max-width: $break1) {
    grid-column:1/3;
  }
  @media screen and (max-width: $break2) {
    grid-column: auto;
    padding-left: 0;
    height: auto;
  }
}
.bloghome > article:first-child .img{
  position:absolute;
  top:0;
  left:0;
  width:63%;
  max-width:none;
  height:450px;

  @media screen and (max-width: $break2) {
    position: static;
    width: auto;
    height:360px;
  }
}
.bloghome > article:first-child a{
  display:block;
  padding-top:40px;
}
