html{
  font-size:62.5%;
  height: 100%;
}
body{
  max-width:1250px;
  margin:0 auto;
  padding:0;
  height: 100%;
  font-family: $defaultFont;
  font-size: 1.7rem;
  line-height: 20px;
  color: $fontColor;
}
body.scrolled{
  padding-top:20px;
}
#container{
  min-height: 100%;
  display: grid;
  grid-template-rows: auto  minmax(0, 1fr) auto;

  main {
    min-width: 0;
  }
}
a{
  color:$linkColor;
  text-decoration:none;
  font-weight:400;
}
a:hover{
  font-weight:400;
  text-decoration:none;
  color: $brandColor;
}
p {
  margin: 18px 0 0 0;
  max-width: 720px;
  padding: 0 10px;
}
h1{
  color: #3c3c3c;
  font-family: $headingFont;
  font-size:3rem;
  line-height:5rem;
  font-weight:bold;
  margin:0 10px 32px 10px;
}

form {
  input[type=text]{
    width: 300px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: none;
    padding: 4px;

    @media screen and (max-width: $break2) {
      box-sizing: border-box;
      width: calc(100% - 20px);
      margin: 0 10px;
    }
  }

  input[type=submit] {
    height: 34px;
    width: 80px;
    padding: 4px;
    background: #eee;
    border: 1px solid #ddd;

    @media screen and (max-width: $break2) {
      display: block;
      margin: 10px auto;
    }
  }
}

#main  h2{
  color: #3c3c3c;
  font-family: $headingFont;
  font-size:2rem;
  line-height:4rem;
  font-weight:bold;
  margin:32px 10px 10px 10px;
}
.content h2 {
  font-weight:bold;
  font-size:2.4rem;
  line-height:3.6rem;
  margin:10px 10px 4px 10px;
}
.content h3 {
  font-weight:bold;
  font-size:1.8rem;
  line-height:3rem;
  margin:10px 10px 4px 10px;
}
.content p{
  line-height: 2em;
  margin: 0 10px 20px 10px;
}
.content ul, .content ol{
  margin: 8px 30px 28px 30px;
}
.content li{
  margin:8px 0;
}
.content ul li, .content ol li{
  position:relative;
  list-style-type: none;
  padding:0 0 0 16px;
  line-height: 2.6rem;
  list-style-position: outside;
}
.content ul li::before{
  position:absolute;
  content: "▶";
  color: #525960;
  left:0;
  width:16px;
  font-size:14px;
  height:100%;
  margin:2px 0;
}
.content ol li{
  list-style-type: decimal;
}
.content strong{
  font-weight: bold;
}
.content em{
  font-style: italic;
}
.content blockquote{
  position:relative;
  padding: 8px 0 0 32px;
  margin:10px 0 10px 20px;
  font-size:1.4rem;
  line-height:1.8rem;
}
.content blockquote p{
  line-height:1.8rem;
  font-size:1.4rem;
}
.content blockquote::before{
  position:absolute;
  bottom: 0;
  left: 0;
  width:30px;
  height:30px;
  content: "„…“";
  font-size:2rem;
  color: $brandColor;
}
.content .box{
  border-left:3px solid $brandColor;
  padding: 0 0 0 6px;
  margin:10px 0 10px 20px;
}
.kicker{
  display: block;
  margin-bottom:8px;
  font-size: 1.1rem;
  line-height:1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: $defaultFont;
  color: #757373;
  font-weight: 700;
}
#header{
  display:flex;
  border-bottom:2px solid #cfd4dd;
  padding:20px 0 30px 0;
}
.logo{
  display:flex;
  color:#191919;
  width:320px;
  flex:1;
  text-decoration:none;
  font-weight:normal;
}
.logo img{
  height:68px;
  width: auto;
  margin-right:10px;
}
.logo span{
  display:block;
}
.logo span:first-child{
  font-family: Georgia, serif;
  font-size:26px;
  line-height:24px;
  margin-bottom:4px;
}
.logo span:last-child{
  font-size:16px;
  padding-left:2px;
}


//build default grid used on home, category pages and authors' pages lateron
.grid {
  display:grid;
  padding:0 20px;
  grid-column-gap: 18px;
  grid-row-gap: 50px;
	grid-template-columns: repeat(3,minmax(280px,1fr));

  @media screen and (max-width: $break1) {
    grid-template-columns: repeat(2,minmax(300px,1fr));
  }
  @media screen and (max-width: $break2) {
    grid-template-columns: repeat(1,minmax(300px,1fr));
  }
  @media screen and (max-width: $break3) {
    padding:0;
  }
}
#main .grid {

  @media screen and (max-width: $break2) {
    padding: 0;
  }
}
.grid .heading{
  font-family: $headingFont;
  font-size:20px;
}
.grid > article{
  position:relative;
}
.grid > article .img{
  display:block;
  max-width:372px;
  height:210px;
  background-size:cover;
  background-position:center center;
  border-radius:4px;
  margin:0 auto;

  @media screen and (max-width: $break2) {
    max-width:none;
    height:320px;
  }
  @media screen and (max-width: $break3) {
    border-radius:0;
  }
}
.grid > article a{
  color: #4c4c4c;
  text-decoration: none;
}
.grid > article h2{
  padding:20px 20px 0 20px;
  margin: 0 !important;
}
.grid > article a:hover h2 span:last-child{
  text-decoration:underline;
}
.grid > article p{
  color: $fontReticient;
  font-size:14px;
  line-height:1.5em;
  padding:10px 20px 0 20px;
  text-align:justify;
}

.head > div {
  width: 50%;
}

.head .grid {
  float: right;
  display: block;
  margin: 0 0 20px 20px;
  max-width: 400px !important;
}

.head .grid > article .img {
  max-width: none;
}

.head .grid>article h2 {
  padding-left: 10px;
  padding-right: 10px;
}

.head .grid>article p {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
}

.changes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: $break3) {
    display: block;
  }
}

.previews{
  flex: 1 1 0px;
  min-width: 280px;
  padding: 0 20px;

  @media screen and (max-width: $break1) {
    width: calc(50% - 40px);
  }
}

.previews article{
  margin: 10px 0;
  min-height: 90px;
}

.previews h3{
  font-size:2rem;
  margin: 20px 0;
}

.previews article h4{
  font-weight: bold;
}

.previews article p{
  margin: 0;
  padding: 0;
}

.previews article span{
  color: #999;
  font-size:12px;
  display: block;
  text-align: left;
}
